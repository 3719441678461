import React from "react";
import * as Dapp from "@elrondnetwork/dapp";
import { routeNames } from "../../routes";
import { getCollectionNFTCount, getWalletNFTs } from "../../helpers/asyncRequests";
import { CollectionInfo, DecodedNFTDetailsType, RawTransactionType } from "../../helpers/types";
import useNewTransaction from "../../pages/Transaction/useNewTransaction";

const DescriptionPanel = ({ collectionInfo }: { collectionInfo: CollectionInfo }) => {
    const { loggedIn } = Dapp.useContext();
    const [remainingNFTsCount, setRemainingNFTsCount] = React.useState<number>(0);
    const sendTransaction = Dapp.useSendTransaction();
    const newTransaction = useNewTransaction();

    const legacyFetchData = () => {
        getWalletNFTs({
            address: collectionInfo.smartContractAddress,
        }).then(({ data }) => {
            if (!data?.data?.esdts)
                return;
            setRemainingNFTsCount(
                collectionInfo.displayOnlyNonceInRemainingQuantity
                    ? Object.entries(data.data.esdts).map(a => {
                        let x = a[1] as DecodedNFTDetailsType;
                        return x.nonce;
                    }).filter((v, i, a) => a.indexOf(v) === i).length
                    : Object.entries(data.data.esdts).length);
        });
    };

    const generativeFetchData = () => {
        getCollectionNFTCount(collectionInfo.generativeSettings?.ticker || "")
            .then(({ data }) => {
                setRemainingNFTsCount(collectionInfo.totalCount - data);
            })
    };

    const fetchData = () => {
        if (collectionInfo.generativeSettings) {
            generativeFetchData();
            return;
        }
        legacyFetchData();
    };

    React.useEffect(fetchData, []);

    const buyNftTransaction: RawTransactionType = {
        receiver: collectionInfo.smartContractAddress,
        value: collectionInfo.egldPrice,
        gasLimit: 600000000,
        data:
            collectionInfo.smartContractBuyMethod ?? "buyNFT"
    };

    const send = (transaction: RawTransactionType) => (e: React.MouseEvent) => {
        e.preventDefault();
        sendTransaction({
            transaction: newTransaction(transaction),
            callbackRoute: routeNames.transaction
        });
    }

    return <div className="col-sm-6">
        <div className="d-flex align-items-between flex-column bd-highlight mb-3">
            <div className="text-white p-2 mb-auto">
                <h2>{collectionInfo.title}</h2>
                <span className="coll-description">
                    {collectionInfo.description}
                </span>
            </div>
            {(collectionInfo.disableBuyMessage &&
                <span className="d-flex justify-content-center text-white mt-5 mb-5"><b>{collectionInfo.disableBuyMessage}</b></span>) ||
                <>
                    <span className="d-flex justify-content-center text-white mt-5 mb-5">Remaining items: {remainingNFTsCount}/{collectionInfo.totalCount}</span>
                    <div className="p-2">
                        <div className="d-flex flex-column mt-5">
                            {loggedIn ?
                                <a className="custom-button px-sm-4 m-1 mx-sm-3 mt-5" onClick={send(buyNftTransaction)}>
                                    Buy
                                </a> :
                                <a className="custom-button px-sm-4 m-1 mx-sm-3 mt-5" href={`${routeNames.unlock}?from=${window.location.pathname}`}>
                                    Buy
                                </a>
                            }
                            <div className="d-flex justify-content-center text-white">
                                {collectionInfo.egldPrice} EGLD
                            </div>
                        </div>
                    </div>
                </>}
        </div>
    </div>
}

export default DescriptionPanel;