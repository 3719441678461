import { routeNames } from "../../routes";

const Home = () => {
  const extraMargin = {
    marginBottom: "90px"
  };

  const logoStyling = {
    maxWidth: "100%"
  };
  
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="d-flex justify-content-center" style={extraMargin}>
            <img src="/logo-text-sm.png" alt="logo" style={logoStyling} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="d-flex justify-content-center">
            <a
              href={routeNames.collections}
              className="custom-button px-sm-4 m-1 mx-sm-3"
              style={{ width: "280px" }}
            >
              Collections
            </a>
          </div>
        </div>
        {/* <div className="col-sm-6">
          <div className="d-flex justify-content-center">
            <a
              href={routeNames.roadmap}
              className="custom-button px-sm-4 m-1 mx-sm-3"
              style={{ width: "280px" }}
            >
              Roadmap
            </a>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Home;
