import { CollectionInfo } from "../../helpers/types";
import ShowcasePanel from "./showcasePanel";
import DescriptionPanel from "./descriptionPanel";

const Collection = ({ collectionInfo }: { collectionInfo: CollectionInfo }) => {
    return <div className="container mt-5">
        {collectionInfo.invertLayout
            ? <div className="row">
                <DescriptionPanel collectionInfo={collectionInfo} />
                <ShowcasePanel collectionInfo={collectionInfo} />
            </div>
            : <div className="row">
                <ShowcasePanel collectionInfo={collectionInfo} />
                <DescriptionPanel collectionInfo={collectionInfo} />
            </div>
        }
    </div>
}

export default Collection;