import * as Dapp from "@elrondnetwork/dapp";
import { CollectionInfo } from "./helpers/types";

export const dAppName = "Indie NFT Artworks";
export const decimals = 2;
export const denomination = 18;
export const gasPrice = 1000000000;
export const version = 1;
export const gasLimit = 50000;
export const gasPerDataByte = 1500;

export const walletConnectBridge = "https://bridge.walletconnect.org";
export const walletConnectDeepLink =
  "https://maiar.page.link/?apn=com.elrond.maiar.wallet&isi=1519405832&ibi=com.elrond.maiar.wallet.dev&link=https://maiar.com/";

// export const network: Dapp.NetworkType = {
//   id: "devnet",
//   name: "Devnet",
//   egldLabel: "xEGLD",
//   walletAddress: "https://devnet-wallet.elrond.com",
//   apiAddress: "https://devnet-api.elrond.com",
//   gatewayAddress: "https://devnet-gateway.elrond.com",
//   explorerAddress: "http://devnet-explorer.elrond.com/",
// };

export const network: Dapp.NetworkType = {
  id: "mainnet",
  name: "Mainnet",
  egldLabel: "EGLD",
  walletAddress: "https://wallet.elrond.com",
  apiAddress: "https://api.elrond.com",
  gatewayAddress: "https://gateway.elrond.com",
  explorerAddress: "http://explorer.elrond.com/",
};

export const elrondTeamCollInfo: CollectionInfo = {
  smartContractAddress: "erd1qqqqqqqqqqqqqpgq6kdhv960taltkl087264el5y9uaa3ut7000q7dhwua",
  totalCount: 250,
  egldPrice: "1.58",
  invertLayout: false,
  title: "Elrond Team Clean Collection",
  description: "This is a pixel art collection (Punk style) composed of 35 individual NTFs, namely 34 characters and 1 poster with all the team members. The collection is minted in 250 editions ONLY. Price shown includes the full edition (35 NFTs).",
  showcaseId: 0,
  displayOnlyNonceInRemainingQuantity: true,
  filesSettings: {
    max: 35,
    publicFolderName: "punks"
  }
};

export const thugLifeCollInfo: CollectionInfo = {
  smartContractAddress: "erd1qqqqqqqqqqqqqpgqdzumn8cwzs7v03qwzypaqhyxyp235vyz000qafklj2",
  totalCount: 250,
  egldPrice: "0.99",
  invertLayout: false,
  title: "Elrond Team Thug Life Collection",
  description: "The Thug Life Collection is a pixel art collection (Punk style) composed of 35 individual NTFs, namely 34 characters and 1 poster with all the team members, in the Thug Life variant. The collection is minted in 250 editions ONLY. Price shown includes the full edition (35 NFTs).",
  showcaseId: 0,
  displayOnlyNonceInRemainingQuantity: true,
  smartContractBuyMethod: "buyThugLifeEdition",
  filesSettings: {
    max: 35,
    publicFolderName: "thuglife",
    extension: ".png"
  }
};

export const minimalistCollInfo: CollectionInfo = {
  smartContractAddress: "erd1qqqqqqqqqqqqqpgqajscvuxttfj3rd5t2hm2xanylagt2aes000qkfh2kc",
  totalCount: 250,
  egldPrice: "0.99",
  invertLayout: false,
  title: "Elrond Team Minimalist Collection",
  description: "The Minimalist Collection is a drawing collection composed of 34 individual NFTs with each of the team's members. The drawing techinque used is the simple and uninterrupted line",
  showcaseId: 0,
  displayOnlyNonceInRemainingQuantity: true,
  smartContractBuyMethod: "buyMinimalistEdition",
  filesSettings: {
    max: 35,
    publicFolderName: "minimalist",
  }
};

export const legendsCollInfo: CollectionInfo = {
  smartContractAddress: "erd1qqqqqqqqqqqqqpgqs9qrp5vjxdzqtcn6pc8d07heejmpj8qy000qttqdlt",
  totalCount: 250,
  egldPrice: "0.99",
  invertLayout: false,
  title: "Elrond Team Legends Collection",
  description: "Elrond Legends is a collection dedicated to those who contributed on Twitter and Telegram to the recognition of the Elrond Network team based on LunarCrush ranking and their activity on Telegram.",
  showcaseId: 0,
  displayOnlyNonceInRemainingQuantity: true,
  smartContractBuyMethod: "buyLegendsEdition",
  filesSettings: {
    max: 33,
    publicFolderName: "legends",
    extension: ".png"
  }
};

export const beniaminLOTRCollInfo: CollectionInfo = {
  smartContractAddress: "erd1qqqqqqqqqqqqqpgq542vlgrdymh2pwl74lzkhxveaelgz5ds000q4adfjs",
  totalCount: 250,
  egldPrice: "0.09",
  invertLayout: false,
  title: "Beniamin LOTR",
  description: "This is the first character of the LOTR collection. Art technique used here is digital drawing. It will be minted in 250 editions ONLY.",
  showcaseId: 1,
  displayOnlyNonceInRemainingQuantity: false,
};

export const auction: CollectionInfo = {
  smartContractAddress: "erd1qqqqqqqqqqqqqpgqpzhw60egs7s2fptlk5s87llej3qkkl7y000qry3gqy",
  totalCount: 1,
  egldPrice: "0",
  invertLayout: false,
  title: "",
  description: "",
  showcaseId: 2,
  displayOnlyNonceInRemainingQuantity: true,
};
//sale 2 bulk; sale 1 = christmas
export const caricatureCollInfo: CollectionInfo = {
  smartContractAddress: "erd1qqqqqqqqqqqqqpgq8sprvx5wcjlxxu03vnjp3f33cjqc3r6ayl5s6hyk62",
  totalCount: 99,
  egldPrice: "0.99",
  invertLayout: false,
  title: "Elrond Team Caricature Collection",
  description: "The Caricature Collection is a drawing collection composed of 34 individual NFTs with each of the team's members",
  showcaseId: 0,
  displayOnlyNonceInRemainingQuantity: true,
  smartContractBuyMethod: "mintBulkNft@02",
  filesSettings: {
    max: 34,
    publicFolderName: "caricature",
    extension: ".jpeg"
  },
  generativeSettings: {
    ticker: "ADIVCATEAM-599068"
  }
};

export const beniamin3d: CollectionInfo = {
  smartContractAddress: "erd1qqqqqqqqqqqqqpgq8sprvx5wcjlxxu03vnjp3f33cjqc3r6ayl5s6hyk62",
  totalCount: 99,
  egldPrice: "0.49",
  invertLayout: false,
  title: "Beniamin 3D Edition",
  description: "This is the 3D representation of the BeniaminMLOTR Collection, minted in only 99 editions",
  showcaseId: 2,
  displayOnlyNonceInRemainingQuantity: false,
  videoSrc: "/benim3d.mp4",
  generativeSettings: {
    ticker: "BMLOTR3D-599068"
  },
  smartContractBuyMethod: "mintSingleNft@03",
};

export const beniamin3dSpecialEdition: CollectionInfo = {
  smartContractAddress: "",
  totalCount: 250,
  egldPrice: "0.09",
  invertLayout: false,
  title: "Beniamin 3D Special Christmas Edition",
  description: "This is the Christmas variant of the Beniamin3D NFT",
  showcaseId: 2,
  displayOnlyNonceInRemainingQuantity: false,
  videoSrc: "/benim3dse.mp4",
  disableBuyMessage: "Minted only for whitelisted members"
};