import Collection from "../../components/Collection";
import { elrondTeamCollInfo, beniaminLOTRCollInfo } from "../../config";
import { routeNames } from "../../routes";
import { Link, useHistory } from "react-router-dom";
import NFTCarousel from "../../components/NFTCarousel";
import * as config from "../../config";
import { LocalFilesSettings } from "../../helpers/types";
import ImageGallery, { ReactImageGalleryProps } from 'react-image-gallery';
import React from "react";
import ReactImageGallery from "react-image-gallery";

const Collections = () => {
    const history = useHistory();

    const getImages = (settings: LocalFilesSettings) => {
        let arr = [];
        for (let i = 1; i < settings.max; i++) {
            arr.push({ original: `/${settings.publicFolderName}/` + i + (settings.extension ?? ".jpg") });
        }
        return arr;
    }

    const pixelRef = React.createRef<ReactImageGallery>();
    const minimalistRef = React.createRef<ReactImageGallery>();

    return <div className="container">
        <div className="row">
            <div className="col-sm-6 mt-3">
                <div className="container">
                    <div className="row">
                        <div className="col d-flex justify-content-center">
                            {/* <img className="collections-img" src="/coming-soon.jpg" alt="coming soon"
                                onClick={() => history.push(routeNames._3dModelCollections)}
                            /> */}
                            <video loop className="collections-img" autoPlay
                                onClick={() => history.push(routeNames._3dModelCollections)}
                            >
                                <source src="benim3d.mp4" type="video/mp4" />
                            </video>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col d-flex justify-content-center">
                            <a href={routeNames._3dModelCollections} className="collections-link">
                                3D Models
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-sm-6">
                <div className="container mt-3">
                    <div className="row">
                        <div className="col d-flex justify-content-center"
                            onClick={() => history.push(routeNames.drawingArtCollections)}
                        >
                            <img className="collections-img" src="/caricature/22.jpeg" alt="beni-m-caricature" onClick={() => history.push(routeNames.drawingArtCollections)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col d-flex justify-content-center">
                            <a href={routeNames.drawingArtCollections} className="collections-link">
                                Drawing Art
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-sm-6 mt-3">
                <div className="container">
                    <div className="row">
                        <div className="col d-flex justify-content-center"
                            onClick={() => history.push(routeNames.pixelArtCollections)}
                        >
                            <img className="collections-img" src="/punks/33.jpg" alt="pixel art" />

                        </div>
                    </div>
                    <div className="row">
                        <div className="col d-flex justify-content-center">
                            <a href={routeNames.pixelArtCollections} className="collections-link">
                                Pixel Art
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-6 mt-3">
                <div className="container">
                    <div className="row">
                        <div className="col d-flex justify-content-center">
                            <img className="collections-img" src="/coming-soon.jpg" alt="coming soon"
                                onClick={() => history.push(routeNames.voxelArtCollections)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col d-flex justify-content-center">
                            <a href={routeNames.voxelArtCollections} className="collections-link">
                                Voxel Art
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default Collections;