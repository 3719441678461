import React, { useEffect } from "react";
import * as Dapp from "@elrondnetwork/dapp";
import { routeNames } from "../../routes";
import { getWalletNFTs } from "../../helpers/asyncRequests";
import { DecodedNFTDetailsType, RawTransactionType } from "../../helpers/types";
import useNewTransaction from "../../pages/Transaction/useNewTransaction";
import { Address, ContractFunction, ProxyProvider, SmartContract, TypedValue, U32Value } from "@elrondnetwork/erdjs/out";
import * as config from "../../config";
import Countdown from 'react-countdown';
import { decode } from "bech32-buffer";

const Auction = () => {
    const { loggedIn, address, account: { balance } } = Dapp.useContext();

    const [auctionId, setAuctionId] = React.useState<number>(); //getauction
    const [currentWinningBid, setCurrentWinningBid] = React.useState<number>();
    const [startTime, setStartTime] = React.useState<number>(); //getdeadline(auctionid)
    const [deadline, setDeadline] = React.useState<number>(); //getdeadline(auctionid)
    const [nftData, setNftData] = React.useState<DecodedNFTDetailsType>(); //from api call
    const [currentBid, setCurrentBid] = React.useState<number>();
    const [currentWinner, setCurrentWinner] = React.useState<string>();

    const [hasStarted, setHasStarted] = React.useState<boolean>();
    const [hasEnded, setHasEnded] = React.useState<boolean>();
    const [startTimeDate, setStartTimeDate] = React.useState<Date>(); //getdeadline(auctionid)
    const [deadlineDate, setDeadlineDate] = React.useState<Date>(); //getdeadline(auctionid)

    const [didMount, setDidMount] = React.useState<boolean>(false);

    const sendTransaction = Dapp.useSendTransaction();
    const newTransaction = useNewTransaction();

    const fetchQueryHex = (method: string, args: TypedValue[]) => new Promise<string>(async (resolve, reject) => {
        let contract = new SmartContract({ address: new Address(config.auction.smartContractAddress) });
        let provider = new ProxyProvider(config.network.gatewayAddress || "");
        let response = await contract.runQuery(provider, {
            func: new ContractFunction(method),
            args: args
        });
        if (response.isSuccess()) {
            if (response.returnData !== undefined) {
                const [encoded] = response.returnData;
                if (!encoded)
                    resolve("-1");
                const decoded = Buffer.from(encoded || "", "base64").toString("hex");
                resolve(decoded);
            }
        }
        else {
            reject("Something went wrong");
        }
    });

    const fetchData = () => {
        if (nftData)
            return;
        getWalletNFTs({
            address: config.auction.smartContractAddress,
        }).then(({ data }) => {
            if (!data?.data?.esdts)
                return;
            const tempNftData = Array<DecodedNFTDetailsType>();
            for (const [key, value] of Object.entries(data.data.esdts)) {
                const parsedValue = value as DecodedNFTDetailsType;
                if (parsedValue.uris === undefined) continue;
                const identifierSplit = key.split("-");
                parsedValue.identifier = identifierSplit[0] + "-" + identifierSplit[1];
                parsedValue.attributes = atob(parsedValue.attributes || "");
                for (let i = 0; i < parsedValue.uris.length; i++) {
                    parsedValue.uris[i] = atob(parsedValue.uris[i]);
                }
                tempNftData.push(parsedValue);
            }
            setNftData(tempNftData[0]);
        });
    };

    const fetchStateData = () => new Promise(async (resolve, _) => {
        fetchQueryHex("getLastValidAuctionId", [])
            .then((auctIdHex) => {
                if (auctIdHex == "-1") {
                    return;
                }
                let auctId = parseInt(auctIdHex, 16);
                Promise.all([
                    fetchQueryHex("getCurrentWinningBid", [new U32Value(auctId)]),
                    fetchQueryHex("getDeadline", [new U32Value(auctId)]),
                    fetchQueryHex("getCurrentWinner", [new U32Value(auctId)]),
                    fetchQueryHex("getStartTime", [new U32Value(auctId)]),
                ])
                    .then(([currentWinningBidHex, deadlineUnixHex, winningAddressHex, startTimeHex]) => {
                        let currentWinningBid = parseInt(currentWinningBidHex, 16);
                        let deadlineUnix = parseInt(deadlineUnixHex, 16);
                        let starttimeUnix = parseInt(startTimeHex, 16);

                        setCurrentWinner(winningAddressHex);
                        if (currentWinningBidHex !== "-1") {
                            setCurrentWinningBid(currentWinningBid / (1_000_000_000_000_000_000));
                        } else {
                            setCurrentWinningBid(1);
                        }
                        setDeadline(deadlineUnix * 1000);
                        setStartTime(starttimeUnix * 1000);
                        setStartTimeDate(new Date(starttimeUnix * 1000));
                        setDeadlineDate(new Date(deadlineUnix * 1000));

                        setHasStarted(starttimeUnix * 1000 < Date.now());
                        setHasEnded(deadlineUnix * 1000 < Date.now());
                    });
                setAuctionId(auctId);
            });
        resolve(undefined);
    });

    const parseIntToHex = (val: number) => {
        const encodedRoyalties = val.toString(16);
        if (encodedRoyalties.length % 2 != 0) return "0" + encodedRoyalties;
        return encodedRoyalties;
    };

    const getCollectionTicker = () => {
        let spl = nftData?.tokenIdentifier.split('-');
        if (spl === undefined)
            return "";
        return Buffer.from(`${spl[0]}-${spl[1]}`).toString("hex");
    }

    const bidTransaction: RawTransactionType = {
        receiver: config.auction.smartContractAddress,
        value: currentBid?.toString() || "0",
        gasLimit: 60000000,
        data:
            `bid@${parseIntToHex(auctionId || 0)}` +
            `@${getCollectionTicker()}` +
            `@${parseIntToHex(nftData?.nonce || 0)}`
    };

    const endAuctionTransaction: RawTransactionType = {
        receiver: config.auction.smartContractAddress,
        value: "0",
        gasLimit: 60000000,
        data:
            `endAuction@${parseIntToHex(auctionId || 0)}`
    };

    const sendBid = (transaction: RawTransactionType) => (e: React.MouseEvent) => {
        e.preventDefault();
        let floatVal = parseFloat(transaction.value);
        // console.log(parseFloat(balance) / 1_000_000_000_000_000_000);
        // console.log(floatVal);
        // console.log(currentBid);
        // console.log(currentWinningBid);
        
        if (floatVal > 0) {
            if (!currentBid) {
                window.alert('Invalid bid amount!');
                return;
            }
            if ((currentWinningBid || 1) >= floatVal) {
                window.alert('You need to outbid the current bid!');
                return;
            }
            if (floatVal > (parseFloat(balance) / 1_000_000_000_000_000_000)) {
                window.alert('You don\'t have enough balance!');
                return;
            }
        }
        else {
            window.alert('You can\'t bid zero!');
            return;
        }
        sendTransaction({
            transaction: newTransaction(transaction),
            callbackRoute: routeNames.transactionFromAuction
        });
    }

    const send = (transaction: RawTransactionType) => (e: React.MouseEvent) => {
        e.preventDefault();
        sendTransaction({
            transaction: newTransaction(transaction),
            callbackRoute: routeNames.transactionFromAuction
        });
    }


    useEffect(() => {
        const interval = setInterval(() => fetchStateData(), 1000);
        const intervalNft = setInterval(() => fetchData(), 1000);
        setDidMount(true);
        return () => {
            clearInterval(interval);
            clearInterval(intervalNft);
        };
    }, []);

    const winningBidMessage = () => {
        if (hasEnded && nftData)
            return <span className="text-white mt-2">Highest bid: {currentWinningBid} EGLD</span>;
        if (hasStarted && !hasEnded)
            return <span className="text-white mt-2">Current highest bid: {currentWinningBid} EGLD</span>;
        return null;
    }

    const isUserCurrentLeader = () => currentWinner && address && address != null && currentWinner === Buffer.from(decode(address || "").data || new Uint8Array()).toString("hex");

    const buyButton = () => {
        if (!loggedIn)
            return <a className="btn btn-primary px-sm-4 m-1 mx-sm-3 mt-5" href={routeNames.unlockFromAuction}>
                Login
            </a>
        if (hasEnded) {
            if (isUserCurrentLeader())
                return <a className="btn btn-primary px-sm-4 m-1 mx-sm-3 mt-5" onClick={send(endAuctionTransaction)}>
                    Claim
                </a>
            return null;
        }

        if (hasStarted && !isUserCurrentLeader())
            return <a className="btn btn-primary px-sm-4 m-1 mx-sm-3 mt-3" onClick={sendBid(bidTransaction)}>
                Bid
            </a>

        return null;
    }

    return !didMount || !startTimeDate || !deadlineDate
        ? <div className="container">
            <div className="row">
                <div className="col-sm-6 offset-sm-3">
                    <div className="d-flex justify-content-center">
                        <span className="mr-2">There is no active auction available</span>
                    </div>
                </div>
            </div>
        </div>
        : <>
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 offset-sm-3">
                        <div className="d-flex justify-content-center">
                            {!hasStarted && !hasEnded && <span className="mr-2">The auction will start in: </span>}
                            {hasStarted && !hasEnded && <span className="mr-2">The auction will end in: </span>}
                            {hasEnded && <span className="mr-2">There is no active auction available</span>}
                            {!hasStarted && !hasEnded &&
                                <Countdown
                                    className="text-white"
                                    date={startTimeDate}
                                    intervalDelay={1}
                                    autoStart={true}
                                />}
                            {hasStarted && !hasEnded &&
                                <Countdown
                                    className="text-white"
                                    date={deadlineDate}
                                    intervalDelay={1}
                                    autoStart={true}
                                />
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6 offset-sm-3">
                        <div className="d-flex justify-content-center">
                            <img src={nftData?.uris[0] || ""} style={{ maxHeight: "350px" }} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6 offset-sm-3">
                        <div className="d-flex justify-content-center">
                            {winningBidMessage()}
                        </div>
                    </div>
                </div>
                {isUserCurrentLeader() && !hasEnded &&
                    <div className="row">
                        <div className="col-sm-6 offset-sm-3">
                            <div className="d-flex justify-content-center">
                                <span className="text-white mt-2"><b>You are the highest bidder</b></span>
                            </div>
                        </div>
                    </div>
                }
                {loggedIn && balance &&
                    <div className="row">
                        <div className="col-sm-6 offset-sm-3">
                            <div className="d-flex justify-content-center">
                                <span className="text-white mt-2">Your balance: {(parseFloat(balance) / 1_000_000_000_000_000_000).toString()} EGLD</span>
                            </div>
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-sm-6 offset-sm-3">
                        <div className="d-flex justify-content-center mt-5">
                            {!isUserCurrentLeader() && !hasEnded && loggedIn && hasStarted &&
                                <input
                                    type="text"
                                    onChange={(e) => setCurrentBid(parseFloat(e.target.value?.replaceAll(',', '.')))}
                                    className="text-white"
                                    style={{
                                        background: "black", border: "1px solid #648CFE",
                                        // borderImageSlice: "1",
                                        // borderImageSource: "linear-gradient(to bottom, #648CFE, )"
                                    }}
                                    placeholder="Enter your bid"
                                />
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6 offset-sm-3">
                        <div className="d-flex justify-content-center">
                            {buyButton()}
                        </div>
                    </div>
                </div>
            </div>
        </>

}

export default Auction;