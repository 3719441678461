import React from "react";
import * as Dapp from "@elrondnetwork/dapp";
import { getWalletNFTs } from "../../helpers/asyncRequests";
import { DecodedNFTDetailsType } from "../../helpers/types";
import NFT from "../../components/NFT";

const Wallet = () => {
    const [walletNfts, setWalletNFTs] = React.useState<Array<DecodedNFTDetailsType>>();

    const { address } = Dapp.useContext();

    const shouldShow = (collIdentifier: string) => {
        return true;
        // for (const allowedCollection of enabledWalletCollections) {
        //     if (collIdentifier.startsWith(allowedCollection))
        //         return true;
        // }
        // return false;
    };

    const fetchData = () => {
        getWalletNFTs({
            address: address,
        }).then(({ data }) => {
            const tempNftData = Array<DecodedNFTDetailsType>();
            for (const [key, value] of Object.entries(data.data.esdts)) {
                const parsedValue = value as DecodedNFTDetailsType;
                if (parsedValue.uris === undefined) continue;
                const identifierSplit = key.split("-");
                parsedValue.identifier = identifierSplit[0] + "-" + identifierSplit[1];

                if (!shouldShow(parsedValue.tokenIdentifier)) continue;

                parsedValue.attributes = atob(parsedValue.attributes || "");
                for (let i = 0; i < parsedValue.uris.length; i++) {
                    parsedValue.uris[i] = atob(parsedValue.uris[i]);
                }
                tempNftData.push(parsedValue);
            }
            setWalletNFTs(tempNftData);
        });
    };

    React.useEffect(fetchData, []);

    return !walletNfts || walletNfts.length === 0 ?
        <div className="container">
            <div className="row">
                <div className="col">
                    <div className="d-flex justify-content-center">
                        <span className="text-white" style={{ fontFamily: "LogoFont", fontSize: "48px" }}>No NFTs available</span>
                    </div>
                </div>
            </div>
        </div>
        :
        <div className="container">
            <div className="row text-white">
                <div className="col-sm-12">
                    <div className="">
                        <span>These are the NFTs available in your wallet:</span>
                    </div>
                </div>
            </div>
            <div className="row">
                {walletNfts.map((wn, key) =>
                    <div className="col">
                        <NFT key={key} nftDetails={wn} />
                    </div>)}
            </div>
        </div>
}

export default Wallet;