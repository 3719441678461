import Collection from "../../../components/Collection";
import { beniamin3d, beniamin3dSpecialEdition } from "../../../config";

const ThreeDModel = () => {
    return <div className="container">
        <div className="row mt-5 mb-5">
            <Collection collectionInfo={beniamin3d} />
        </div>
        <div className="row mt-5 mb-5">
            <Collection collectionInfo={beniamin3dSpecialEdition} />
        </div>
    </div>
}

export default ThreeDModel;