import React from "react";
import * as Dapp from "@elrondnetwork/dapp";
import { dAppName } from "./config";
import withPageTitle from "./components/PageTitle";
import Home from "./pages/Home";
import Collections from "./pages/Collections";
import Roadmap from "./pages/Roadmap";
import Wallet from "./pages/Wallet";
import Transaction from "./pages/Transaction";
import Auction from "./pages/Auction";
import TransactionFromAuction from "./pages/Transaction/transactionFromAuction";
import PixelArt from "./pages/Collections/SubPages/PixelArt";
import VoxelArt from "./pages/Collections/SubPages/VoxelArt";
import ThreeDModel from "./pages/Collections/SubPages/ThreeDModel";
import DrawingArt from "./pages/Collections/SubPages/DrawingArt";

type RouteType = Dapp.RouteType & { title: string };

export const routeNames = {
  home: "/",
  collections: "/collections",
  pixelArtCollections: "/pixel-art",
  drawingArtCollections: "/drawing-art",
  voxelArtCollections: "/voxel-art",
  _3dModelCollections: "/3d-model-art",
  transaction: "/transaction",
  transactionFromAuction: "/auction-transaction",
  unlock: "/unlock",
  unlockFromAuction: "/unlock-auction",
  ledger: "/ledger",
  walletconnect: "/walletconnect",
  walletconnectAuction: "/walletconnect-auction",
  // roadmap: "/roadmap",
  wallet: "/wallet",
  auction: "/whitelisted-auction"
};

const routes: RouteType[] = [
  {
    path: "/",
    title: "Home",
    component: Home,
  },
  {
    path: routeNames.collections,
    title: "Collections",
    component: Collections
  },
  // {
  //   path: routeNames.roadmap,
  //   title: "Roadmap",
  //   component: Roadmap
  // },
  {
    path: routeNames.wallet,
    title: "Wallet",
    component: Wallet
  },
  {
    path: routeNames.transaction,
    title: "Transaction",
    component: Transaction,
  },
  {
    path: routeNames.transactionFromAuction,
    title: "Transaction",
    component: TransactionFromAuction,
  },
  {
    path: routeNames.auction,
    title: "Whitelisted auction",
    component: Auction
  },
  {
    path: routeNames.pixelArtCollections,
    title: "Pixel Art",
    component: PixelArt
  },
  {
    path: routeNames.voxelArtCollections,
    title: "Voxel Art",
    component: VoxelArt
  },
  {
    path: routeNames._3dModelCollections,
    title: "3D Models",
    component: ThreeDModel
  },
  {
    path: routeNames.drawingArtCollections,
    title: "Drawing Art",
    component: DrawingArt
  },
];

const wrappedRoutes = () =>
  routes.map((route) => {
    const title = route.title
      ? `${route.title} • ${dAppName}`
      : `${dAppName}`;
    return {
      path: route.path,
      authenticatedRoute: Boolean(route.authenticatedRoute),
      component: withPageTitle(
        title,
        route.component,
      ) as any as React.ComponentClass<any, any>,
    };
  });

export default wrappedRoutes();
