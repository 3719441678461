import { CollectionInfo } from "../../helpers/types";
import NFTCarousel from "../NFTCarousel";

const ShowcasePanel = ({ collectionInfo }: { collectionInfo: CollectionInfo }) => {

    const getShowcaseById = () => {
        switch (collectionInfo.showcaseId) {
            case 0:
                if (collectionInfo.filesSettings)
                    return <NFTCarousel settings={collectionInfo.filesSettings} />;
                return null;
            case 1:
                return <img src="/beni.jpg" alt="Beniamin LOTR NFT" style={{ maxHeight: "500px" }} />;
            case 2:
                return <video loop className="collections-img" autoPlay>
                    <source src={collectionInfo.videoSrc} type="video/mp4" />
                </video>
        }
        return null;
    }

    return <div className="col-sm-6">
        <div className="d-flex justify-content-center">
            {getShowcaseById()}
        </div>
    </div>
}

export default ShowcasePanel;