const VoxelArt = () => {
    return <div className="container">
        <div className="row">
            <div className="col">
                <div className="d-flex justify-content-center">
                    <span className="text-white" style={{ fontFamily: "LogoFont", fontSize: "48px" }}>Coming soon</span>
                </div>
            </div>
        </div>
    </div>
}

export default VoxelArt;