import React from "react";
import ImageGallery from 'react-image-gallery';
import { LocalFilesSettings } from "../../helpers/types";


const NFTCarousel = ({settings } : {settings: LocalFilesSettings}) => {
    const getImages = () => {
        let arr = [];
        for (let i = 1; i < settings.max; i++) {
            arr.push({ original: `/${settings.publicFolderName}/` + i + (settings.extension ?? ".jpg") });
        }
        return arr;
    }

    return (
        <div>
            <ImageGallery
                items={getImages()}
                showNav={false}
                showThumbnails={false}
                showPlayButton={false}
                infinite={true}
                autoPlay={true}
                slideDuration={1}
                slideInterval={500}
                showFullscreenButton={false}
            />
        </div>
    );
}

export default NFTCarousel;