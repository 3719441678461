import React from "react";

const Footer = () => {
  return (
    <footer className="d-flex justify-content-center mt-2 mb-3 text-muted footer-text">
      Coded and designed by <a {...{ target: "_blank", }} className="d-flex align-items-center ml-1 text-muted footer-link" href="https://twitter.com/ab0lomination">
         ab0lomination
        </a>
    </footer>
  );
};

export default Footer;