import { Card } from "react-bootstrap";
import { DecodedNFTDetailsType } from "../../helpers/types";

const NFT = (nftDetails: { nftDetails: DecodedNFTDetailsType }) => {

    return <Card style={{ maxWidth: "220px", color: "white" }}>
        <Card.Img variant="top" src={nftDetails.nftDetails.uris[0]} alt="NFT image" />
        <Card.Title>{nftDetails.nftDetails.identifier.split('-')[0]}</Card.Title>
        {/* <Card.Body style={{fontSize:"12px"}}> */}
        <Card.Body>
            {nftDetails.nftDetails.identifier.split('-')[0]}
            <br />
            Nonce: {nftDetails.nftDetails.nonce}
        </Card.Body>
    </Card>
}

export default NFT;