import Collection from "../../../components/Collection";
import { elrondTeamCollInfo, thugLifeCollInfo, legendsCollInfo } from "../../../config";

const PixelArt = () => {
    return <div className="container">
        <div className="row">
            <Collection collectionInfo={elrondTeamCollInfo} />
        </div>
        <div className="row">
            <Collection collectionInfo={thugLifeCollInfo} />
        </div>
        <div className="row">
            <Collection collectionInfo={legendsCollInfo} />
        </div>
    </div>
}

export default PixelArt;