import React from "react";
import * as Dapp from "@elrondnetwork/dapp";
import { Navbar as BsNavbar, NavItem, Nav, NavDropdown, Container } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { dAppName } from "../../../config";
import { routeNames } from "../../../routes";

const Navbar = () => {
  const { loggedIn } = Dapp.useContext();
  const dappLogout = Dapp.useLogout();
  const history = useHistory();

  const logOut = (e: React.MouseEvent) => {
    e.preventDefault();
    dappLogout({ callbackUrl: `${window.location.origin}/` });
    history.push("/");
  };

  const brandNameImgStyle = {
    maxHeight: "30px",
  };

  const navbarStyle = {
    background: "black",
  };

  const isOnLandingPage = () => window.location.href === `${window.location.origin}/`;
  const isOnWhiteListedAuction = () => window.location.href === `${window.location.origin}/whitelisted-auction`;

  return isOnLandingPage() ? null : (
    <BsNavbar className="px-4 py-3" style={navbarStyle} expand="lg">
      <Container>
        <BsNavbar.Brand href={routeNames.collections}>
          <div>
            <img src="/logoindie.png" alt="logo" className="navbar-logo" />
            <img src="/logo-text-only.png" alt="logo name" style={brandNameImgStyle} className="navbar-logo-brand-indie" />
          </div>
        </BsNavbar.Brand>
        <BsNavbar.Toggle aria-controls="navbar-nav" className="text-white" style={{ backgroundColor: "white" }} />
        <BsNavbar.Collapse id="navbar-nav">
          <Nav className="ml-auto">
            {/* <Nav.Link href={routeNames.collections} className="navbar-btn mr-5">
              Collections
            </Nav.Link>
             */}
            <NavDropdown title="Collections" className="navbar-btn mr-5 text-white" id="coll-dropdown">
              <NavDropdown.Item href={routeNames._3dModelCollections}>
                3D Models
              </NavDropdown.Item>
              <NavDropdown.Item href={routeNames.drawingArtCollections}>
                Drawing Art
              </NavDropdown.Item>
              <NavDropdown.Item href={routeNames.pixelArtCollections}>
                Pixel Art
              </NavDropdown.Item>
              <NavDropdown.Item href={routeNames.voxelArtCollections}>
                Voxel Art
              </NavDropdown.Item>
            </NavDropdown>
            {/* <Nav.Link href={routeNames.roadmap} className="navbar-btn mr-5">
              Roadmap
            </Nav.Link> */}
            {loggedIn ? (
              <>
                <Nav.Link className="navbar-btn mr-5" href={routeNames.wallet}>
                  <a >NFT Wallet</a>
                </Nav.Link>
                <Nav.Link href={routeNames.home} onClick={logOut} className="navbar-btn">
                  Logout
                </Nav.Link>
              </>
            ) : (
              <Nav.Link href={isOnWhiteListedAuction() ? routeNames.unlockFromAuction : routeNames.unlock} className="navbar-btn">
                Login ⚡
              </Nav.Link>
            )}
          </Nav>
        </BsNavbar.Collapse>
      </Container>
      {/* </div> */}
    </BsNavbar>
  );
};

export default Navbar;
