import * as Dapp from "@elrondnetwork/dapp";
import { useLocation } from "react-router-dom";
import { routeNames } from "../../routes";

const Connect = () => {
    const { search } = useLocation();  
    const query = new URLSearchParams(search);
    const { from } = Object.fromEntries(query);
    console.log(from);
    return <Dapp.Pages.Unlock
        callbackRoute={from ?? routeNames.collections}
        title="Please select your login method:"
        lead=""
        ledgerRoute={`/ledger${from ? `?from=${from}`: ``}`} /* route after choosing ledger login */
        walletConnectRoute={`/walletconnect${from ? `?from=${from}`: ``}`} /* route after choosing Maiar login */
    />;
}

export default Connect;