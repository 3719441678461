import axios from "axios";
import * as config from "../config";

interface GetCollectionsType {
    address: string;
}

const fetchWalletNFTs = () =>
    async function getWalletNFTs({ address }: GetCollectionsType) {
        try {
            const { data } = await axios.get(
                `${config.network.gatewayAddress}/address/${address}/esdt`
            );
            return {
                data: data,
                success: data !== undefined,
            };
        } catch (err) {
            return {
                success: false,
            };
        }
    };

const fetchCollectionNftCount = () =>
    async function getCollectionNftCount(collectionTicker: string) {
        try {
            const { data } = await axios.get(
                `${config.network.apiAddress}/nfts/count?collection=${collectionTicker}`
            );
            return {
                data: data,
                success: data !== undefined,
            };
        } catch (err) {
            return {
                success: false,
            };
        }
    };

export const getWalletNFTs = fetchWalletNFTs();
export const getCollectionNFTCount = fetchCollectionNftCount();