import Collection from "../../../components/Collection";
import { beniaminLOTRCollInfo, caricatureCollInfo, minimalistCollInfo } from "../../../config";


const DrawingArt = () => {
    return <div className="container">
        <div className="row mt-5 mb-5">
            <Collection collectionInfo={beniaminLOTRCollInfo} />
        </div>
        <div className="row mt-5 mb-5">
            <Collection collectionInfo={minimalistCollInfo} />
        </div>
        <div className="row mt-5 mb-5">
            <Collection collectionInfo={caricatureCollInfo} />
        </div>
    </div>
}

export default DrawingArt;