import React from "react";
import * as Dapp from "@elrondnetwork/dapp";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ContextProvider } from "./context";
import * as config from "./config";
import Layout from "./components/Layout";
import routes, { routeNames } from "./routes";
import PageNotFound from "./components/PageNotFoud";
import Connect from "./pages/Connect";
import MaiarConnect from "./pages/Connect/maiar";
import LedgerConnect from "./pages/Connect/ledger";

function App() {
  return (
    <>
      <Router basename={process.env.PUBLIC_URL}>
        <ContextProvider>
          <Dapp.Context
            config={config}
          >
            <Layout>
              <Switch>
              <Route
                  path={routeNames.unlockFromAuction}
                  component={() => (
                    <Dapp.Pages.Unlock
                      callbackRoute="/whitelisted-auction" /* route after successfull login */
                      title="Please select your login method:"
                      lead=""
                      ledgerRoute="/ledger" /* route after choosing ledger login */
                      walletConnectRoute="/walletconnect-auction" /* route after choosing Maiar login */
                    />
                  )}
                  exact={true}
                />
                <Route
                  path={routeNames.unlock}
                  component={Connect}
                  exact={true}
                />
                <Route
                  path="/ledger" /* must correspond to ledgerRoute */
                  component={LedgerConnect}
                  exact={true}
                />
                <Route
                  path="/walletconnect" /* must correspond to walletConnectRoute */
                  component={MaiarConnect}
                  exact={true}
                />
                <Route
                  path="/walletconnect-auction" /* must correspond to walletConnectRoute */
                  component={() => (
                    <Dapp.Pages.WalletConnect
                      callbackRoute={routeNames.auction}
                      logoutRoute="/" /* redirect after logout */
                      title="Maiar Login"
                      lead="Scan the QR code using Maiar"
                    />
                  )}
                  exact={true}
                />
                {routes.map((route, i /* rest of routes */) => (
                  <Route
                    path={route.path}
                    key={route.path + i}
                    component={route.component}
                    exact={true}
                  />
                ))}
                <Route component={PageNotFound} />
              </Switch>
            </Layout>
          </Dapp.Context>
        </ContextProvider>
      </Router>
    </>
  );
}

export default App;
