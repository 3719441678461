import * as Dapp from "@elrondnetwork/dapp";
import { useLocation } from "react-router-dom";
import { routeNames } from "../../routes";


const MaiarConnect = () => {
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const { from } = Object.fromEntries(query);

    return <Dapp.Pages.WalletConnect
        callbackRoute={from ?? routeNames.collections}
        logoutRoute="/" /* redirect after logout */
        title="Maiar Login"
        lead="Scan the QR code using Maiar"
    />;
}

export default MaiarConnect;